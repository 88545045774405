import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";

const address = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useUpdateInterval = () => {
  const {
    isError: isUpdateIntervalSentError,
    data: updateIntervalSentTx,
    isLoading: isUpdateIntervalTxInProgress,
    isSuccess: isUpdateIntervalTxSent,
    write: updateInterval, // Function to interact with the updateInterval contract
    error: updateIntervalSentError
  } = useContractWrite({
    address,
    abi,
    functionName: "setInterval", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isUpdateIntervalWaiting,
    isSuccess: isUpdateIntervalCompleted,
    isError: isUpdateIntervalError,
    error: updateIntervalTxError,
    data: updateIntervalResp
  } = useWaitForTransaction({
    hash: updateIntervalSentTx?.hash,
  });

  return {
    // updateInterval operation
    isUpdateIntervalSentError,
    updateIntervalSentTx,
    isUpdateIntervalTxInProgress,
    isUpdateIntervalTxSent,
    updateInterval,
    isUpdateIntervalCompleted,
    isUpdateIntervalError,
    isUpdateIntervalWaiting,
    updateIntervalSentError,
    updateIntervalTxError,
    updateIntervalResp
  };
};

export default useUpdateInterval;
