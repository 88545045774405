import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";
import { useEffect } from "react";
import { toast } from "react-toastify";

const setTreasuryPercentageContractAddress = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useSetTreasuryPercentage = ({setLoader}) => {
  const {
    isError: isSetTreasuryPercentageSentError,
    data: setTreasuryPercentageSentTx,
    isLoading: isSetTreasuryPercentageTxInProgress,
    isSuccess: isSetTreasuryPercentageTxSent,
    write: setTreasuryPercentage, // Function to interact with the setTreasuryPercentage contract
    error: setTreasuryPercentageSentError,
  } = useContractWrite({
    address: setTreasuryPercentageContractAddress,
    abi,
    functionName: "setTeasueryPercentages", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isSetTreasuryPercentageWaiting,
    isSuccess: isSetTreasuryPercentageCompleted,
    isError: isSetTreasuryPercentageError,
    error: setTreasuryPercentageTxError,
    data: setTreasuryPercentageResp,
  } = useWaitForTransaction({
    hash: setTreasuryPercentageSentTx?.hash,
  });
  useEffect(() => {
    const error =
      setTreasuryPercentageSentError || setTreasuryPercentageTxError;
    if (error) {
      toast.error(error.message);
      setLoader(false)
    }
  }, [setTreasuryPercentageSentError, setTreasuryPercentageTxError]);
  return {
    // setTreasuryPercentage operation
    isSetTreasuryPercentageSentError,
    setTreasuryPercentageSentTx,
    isSetTreasuryPercentageTxInProgress,
    isSetTreasuryPercentageTxSent,
    setTreasuryPercentage,
    isSetTreasuryPercentageCompleted,
    isSetTreasuryPercentageError,
    isSetTreasuryPercentageWaiting,
    setTreasuryPercentageSentError,
    setTreasuryPercentageTxError,
    setTreasuryPercentageResp,
  };
};

export default useSetTreasuryPercentage;
