// ** WEB3 Imports
import { useState, useEffect } from "react";

import { useContractRead } from "wagmi";
import { ethers } from "ethers";
import { CONTRACT_INFO } from "../../index";
const useGetProjectPoolPercentage = (id) => {
  const [tPoolPercentage, setTPoolPercentage] = useState(null);

  const {
    data: percentage,
    refetch: refetch,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "tPPercentages",
    args:[Number(id)],
    enabled:id>=0
  });
  useEffect(() => {
    if (percentage) {
        setTPoolPercentage(Number(percentage).toString()/100);
    }
  }, [percentage]);

  return {
    percentage:tPoolPercentage,
    refetch,
  };
};

export default useGetProjectPoolPercentage;
