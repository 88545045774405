export const decimalnumbervalidator = (evt, allowDecimals = false, max = null) => {
  let e = evt || window.event;

  // Check for the wheel event and prevent its default action
  if (e.key === "ArrowDown") {
    e.preventDefault();
    return;
  }

  // Allow: backspace, delete, tab, escape, enter and .
  const specialKeys = [46, 8, 9, 27, 13, 110];

  // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Command+A
  if (
    specialKeys.includes(e.keyCode) ||
    ((e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 90 || e.keyCode === 88) &&
      (e.ctrlKey === true || e.metaKey === true)) ||
    (e.keyCode >= 35 && e.keyCode <= 40)
  ) {
    return; // let it happen, don't do anything
  }

  let key = e.keyCode || e.which;

  // Get the input value
  const input = e.target.value;
  const decimalPosition = input.indexOf('.');

  // If decimals are not allowed and the key is a decimal point, prevent input
  if (!allowDecimals && (key === 190 || key === 110)) {
    e.returnValue = false;
    if (e.preventDefault) e.preventDefault();
    return;
  }

  // If decimals are allowed, check for decimal places restriction
  if (allowDecimals) {
    // If there is a decimal point and the number of decimal places is 6 or more, prevent input
    if (decimalPosition !== -1 && input.length - decimalPosition > 6) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
      return;
    }
  }

  // Allow numbers and special keys
  if (
    !e.shiftKey &&
    !e.altKey &&
    !e.ctrlKey &&
    (key === 190 ||
      key === 110 ||
      (key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105) ||
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 35 ||
      key === 36 ||
      key === 37 ||
      key === 39 ||
      key === 46 ||
      key === 45)
  ) {
    // Check if the new value would exceed the max value
    let newValue = input + e.key;
    
    if (allowDecimals && decimalPosition !== -1) {
      newValue = parseFloat(newValue);
    } else {
      newValue = parseInt(newValue, 10);
    }

    if (max !== null && newValue > max) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
      return;
    }

  } else {
    e.returnValue = false;
    if (e.preventDefault) e.preventDefault();
  }
};

  
  export const firstInputCharacterSpace = (evt, value) => {
    let e = evt || window.event;
    if (value === 0 && e.which === 32) e.preventDefault();
  };
  export function calculatePercentage(part, whole,i) {
    return (whole*(part/100))?.toFixed(2)
}