import React, { useState, useEffect } from "react";
import Classes from "./login.module.scss";
import { Col, Row } from "antd";
import BgImage from "../../assets/images/section/loginBg.svg";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/section/logo.svg";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useSignMessage, useAccount, useDisconnect } from "wagmi";
import { validateUser, validateSignature } from "../../api/services/auth";
import { useDispatch } from "react-redux";
import { setUserData, setWalletAddress } from "../../store/_actions/auth_action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function Login() {
  const [darkMode, setDarkMode] = useState(true);
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: signMessageData,
    error,
    isLoading,
    signMessage,
    variables,
  } = useSignMessage();
  useEffect(() => {
    if (address) {
      handleWalletConnect();
    }
  }, [address]);
  useEffect(() => {
    if (address && localStorage.getItem("accessToken")) {
      navigate("/dashboard");
    }
  }, [address]);
  useEffect(() => {
    const handleValidateSignature = async () => {
      try {
        const res = await validateSignature(address, signMessageData);
        if (res?.data?.data?.accessToken) {
          localStorage.setItem("accessToken", res.data.data.accessToken);
          localStorage.setItem("walletAddress", address);
          dispatch(setWalletAddress(address));
          dispatch(setUserData(res.data?.data?.updatedUser))
          toast.success("Logged in successfully");
          navigate("/");
        }
      } catch (err) {
        disconnect();
      }
    };
    const token = localStorage.getItem("accessToken");
    if (signMessageData && !token) {
      handleValidateSignature();
    }
  }, [signMessageData]);
  useEffect(() => {
    if (error) {
      dispatch(setWalletAddress(null));
      disconnect();
    }
  }, [error]);
  const handleWalletConnect = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        const res = await validateUser(address);
        const user = res.data.data;
        signMessage({
          message: `I am signing my one-time nonce: ${user?.nonce}`,
        });
      } else {
        dispatch(setWalletAddress(address));
      }
    } catch (err) {
      disconnect();
    }
  };
  useEffect(() => {
    // On component mount, set initial theme based on darkMode state
    if (darkMode) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.add("light-theme");
    }

    return () => {
      // Cleanup function to remove class on unmount
      document.body.classList.remove("dark-theme");
      document.body.classList.remove("light-theme");
    };
  }, [darkMode]);
  return (
    <div className={Classes.login}>
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <img className={Classes.bgImg} src={BgImage} alt="img" />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <div className={Classes.rightBg}>
            <div className={Classes.card}>
              <Link to="/" className={Classes.logo}>
                <img src={Logo} alt="logo" />
                <p>The Warriors DAO</p>
              </Link>
              <button
                className={Classes.connectBtn}
                onClick={() => open({ view: "Networks" })}
              >
                Connect Wallet
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
