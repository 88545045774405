import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";
import { useEffect } from "react";
import { toast } from "react-toastify";

const address = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useAddProject = ({setLoader}) => {
  const {
    isError: isAddProjectSentError,
    data: addProjectSentTx,
    isLoading: isAddProjectTxInProgress,
    isSuccess: isAddProjectTxSent,
    write: addNewProject, // Function to interact with the addProject contract
    error: addProjectSentError
  } = useContractWrite({
    address,
    abi,
    functionName: "addProjects", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isAddProjectWaiting,
    isSuccess: isAddProjectCompleted,
    isError: isAddProjectError,
    error: addProjectTxError,
    data: addProjectResp
  } = useWaitForTransaction({
    hash: addProjectSentTx?.hash,
  });
  useEffect(()=>{
    if(addProjectSentError,addProjectTxError){
      setLoader(false)
      toast.error(addProjectSentError.message)
    }

  },[addProjectSentError,addProjectTxError])

  return {
    // addProject operation
    isAddProjectSentError,
    addProjectSentTx,
    isAddProjectTxInProgress,
    isAddProjectTxSent,
    addNewProject,
    isAddProjectCompleted,
    isAddProjectError,
    isAddProjectWaiting,
    addProjectSentError,
    addProjectTxError,
    addProjectResp
  };
};

export default useAddProject;
